import React from 'react';
// import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
// core components
import footerStyle from '../../assets/jss/material-dashboard-react/components/footerStyle';

function Footer({ ...props }: any) {
  const { classes } = props;
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
    
        <p className={classes.right}>
          <span>
            &copy; {new Date().getFullYear()}{' '}
            <a href="https://hubone.tech" target="_blank" className={classes.a}>
              HubOne
            </a>
   
          </span>
        </p>
      </div>
    </footer>
  );
}

// Footer.propTypes = {
//   classes: PropTypes.object.isRequired
// };

export default withStyles(footerStyle)(Footer);
