import React, { useState, useEffect } from 'react';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import Table from '../../components/Table/Table';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';
import { Button, createStyles } from '@material-ui/core';
import Swal from 'sweetalert2';

const styles = createStyles({
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0'
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF'
    }
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: 300,
    fontFamily: '\'Roboto\', \'Helvetica\', \'Arial\', sans-serif',
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: 400,
      lineHeight: 1
    }
  }
});
interface Instance {
  siteName: string;
  createdDate: string;
  // Define other properties here as needed
}

function TableList(props: any) {
  const { classes } = props;
  const [tableData, setTableData] = useState<Instance[]>([]);

  useEffect(() => {
    // Fetch data from the /getInstances endpoint
    fetch('https://api.app.hubone.tech/getInstances?ownerId=1') // Use ownerId=1 as an example
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // If the request is successful, update the tableData state with the received data
          setTableData(data.instances);
        } else {
          // Handle any errors here
          console.error('Failed to fetch data from /getInstances:', data.error);
        }
      })
      .catch((error) => {
        console.error('Error while fetching data:', error);
      });
  }, []); // The empty array [] ensures that this effect runs once, similar to componentDidMount
  
  const handleOpenClick = (instance: Instance) => {
    // Generate the URL using the instance.siteName
    const url = `https://app.hubone.tech/${instance.siteName}`;
  
    // Open the URL in a new tab
    window.open(url, '_blank');
  };

  const handleOpenPanelClick = (instance: Instance) => {
    // Generate the URL using the instance.siteName
    const url = `https://app.hubone.tech/${instance.siteName}/wp-admin`;
  
    // Open the URL in a new tab
    window.open(url, '_blank');
  };

  const handleDeleteClick = (instance: Instance) => {
    // Ask for confirmation using SweetAlert2
    Swal.fire({
      title: 'Potwierdź usunięcie',
      text: 'Czy na pewno chcesz usunąć tę instancję?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Tak, usuń!',
      cancelButtonText: 'Anuluj',
      preConfirm: () => {
        return fetch('https://api.app.hubone.tech/deleteInstance', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            site_name: instance.siteName,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              // If the deletion request is successful, update the tableData state
              setTableData((prevTableData) =>
                prevTableData.filter((item) => item.siteName !== instance.siteName)
              );
  
              // Display a success message using SweetAlert2
              Swal.fire({
                icon: 'success',
                title: 'Sukces',
                text: 'Instancja została usunięta!',
              });
            } else {
              // Handle any errors here
              console.error('Wystąpił błąd:', data.error);
              Swal.fire({
                icon: 'error',
                title: 'Błąd',
                text: `Wystąpił błąd: ${data.error}`,
              });
            }
          })
          .catch((error) => {
            console.error('Wystąpił błąd:', error);
            Swal.fire({
              icon: 'error',
              title: 'Błąd',
              text: 'Wystąpił błąd podczas usuwania instancji.',
            });
          });
      },
    });
  };
  

  const handleCloneClick = (instance: Instance) => {
    Swal.fire({
      title: 'Podaj nowy adres',
      html: 'Strona będzie dostępna pod linkiem https://app.hubone.tech/<b>nowy-adres</b>', 
      input: 'text',
      inputValue: `${instance.siteName}`, // Set the default input value
      showCancelButton: true,
      confirmButtonText: 'Klonuj',
      cancelButtonText: 'Anuluj',
      preConfirm: (newSiteName) => {
        // Trigger a request to /duplicateInstance
        return fetch('https://api.app.hubone.tech/duplicateInstance', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            existing_site_name: instance.siteName,
            new_site_name: newSiteName,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              // Update the tableData state with the received data
              setTableData((prevTableData) => [...prevTableData, data.newInstance]);

              // Display a success message using SweetAlert2
              Swal.fire({
                icon: 'success',
                title: 'Sukces',
                text: 'Instancja została utworzona!',
              });

              return true; // Close the input SweetAlert2 dialog
            } else {
              // Handle any errors here
              console.error('Wstąpił błąd:', data.error);
              Swal.showValidationMessage(`Wystąpił błąd: ${data.error}`);
              return false; // Keep the input SweetAlert2 dialog open
            }
          })
          .catch((error) => {
            console.error('Wystąpił błąd2:', error);
            Swal.showValidationMessage('Błąd2.');
            return false; // Keep the input SweetAlert2 dialog open
          });
      },
    });
  };



  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Twoje strony internetowe</h4>
            <p className={classes.cardCategoryWhite}>
              Wszystkie aktywne strony internetowe
            </p>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={['Adres', 'Data utworzenia', 'Otwórz','Panel', 'Klonuj','Usuń']}
              tableData={tableData.map((instance, index) => [
                `${instance.siteName}`,
                `${instance.createdDate}`,
                <Button
                variant="contained"
                color="primary"
                onClick={() => handleOpenClick(instance)}
              >Otwórz</Button>,   
              <Button
              variant="contained"
              color="primary"
              onClick={() => handleOpenPanelClick(instance)}
            >Panel</Button>,
                <Button
                  variant="contained"
                  color="default"
                  onClick={() => handleCloneClick(instance)}
                >
                  Klonuj
                </Button>,
                  <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleDeleteClick(instance)}
                >
                  Usuń
                </Button>,
              ])}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

export default withStyles(styles)(TableList);
