import React, { useState } from 'react';
import axios from 'axios';
import withStyles from '@material-ui/core/styles/withStyles';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import CustomInput from '../../components/CustomInput/CustomInput';
import Button from '../../components/CustomButtons/Button';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';
import Close from '@material-ui/icons/FileCopy';
import CardFooter from '../../components/Card/CardFooter';
import { createStyles } from '@material-ui/core';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { NavLink } from 'react-router-dom';

const styles = createStyles({
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: 300,
    fontFamily: '\'Roboto\', \'Helvetica\', \'Arial\', sans-serif',
    marginBottom: '3px',
    textDecoration: 'none',
  },
});

function UserProfile(props: any) {
  const { classes } = props;

  const [currentStep, setCurrentStep] = useState(1);

  const [step1FormData, setStep1FormData] = useState({
    siteName: '',
  });

  const [step2FormData, setStep2FormData] = useState({
    siteTitle: '',
    siteSlogan: '',
  });

  const [step3FormData, setStep3FormData] = useState({
    adminEmail: '',
    adminLogin: '',
    adminPass: '',
  });

  const [step4FormData, setStep4FormData] = useState({
    theme: '', // Store the selected radio option here
  });

  const [step5FormData, setStep5FormData] = useState({
    // Add new fields for checkboxes in Step 4
    hidegoogle: true,
    contentimport: true,
  });

  const [step1FieldTouched, setStep1FieldTouched] = useState(false);
  const [step2FieldTouched, setStep2FieldTouched] = useState(false);
  const [step3FieldTouched, setStep3FieldTouched] = useState(false);
  const [step4FieldTouched, setStep4FieldTouched] = useState(false);
  const [step5FieldTouched, setStep5FieldTouched] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFormHidden, setIsFormHidden] = useState(false);

  // Email validation function
  const isValidEmail = (email: string) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
  };

  // Check if siteName contains only valid characters
  const isSiteNameValid = /^[a-z][a-z0-9]*(-[a-z0-9]+)*$/.test(step1FormData.siteName);


  const isStep1Valid = step1FormData.siteName.trim() !== '' && isSiteNameValid;
  const isStep2Valid = step2FormData.siteTitle.trim() !== '' && step2FormData.siteSlogan.trim() !== '';
  const isStep3Valid =
    step3FormData.adminEmail.trim() !== '' &&
    isValidEmail(step3FormData.adminEmail) &&
    step3FormData.adminLogin.trim() !== '' &&
    step3FormData.adminPass.trim() !== '';

  const isStep4Valid = step4FormData.theme !== '';

  // Validation function for Step 4
  const isStep5Valid = true;

  const getSelectedOptions = () => {
    const selectedOptions = [];
    if (step5FormData.hidegoogle) selectedOptions.push('Ukrycie widoczności dla Google');
    if (step5FormData.contentimport) selectedOptions.push('Import demo treści i układu');

    return selectedOptions.join(', ');
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked, value } = e.target;
    if (currentStep === 1) {
      setStep1FieldTouched(true);
      setStep1FormData({
        ...step1FormData,
        [id]: value,
      });
    } else if (currentStep === 2) {
      setStep2FieldTouched(true);
      setStep2FormData({
        ...step2FormData,
        [id]: value,
      });
    } else if (currentStep === 3) {
      setStep3FieldTouched(true);
      setStep3FormData({
        ...step3FormData,
        [id]: value,
      });
    } else if (currentStep === 4) {
      setStep4FieldTouched(true);
      setStep4FormData({
        ...step4FormData,
        [id]: value,
      });
    } else if (currentStep === 5) {
      setStep5FieldTouched(true);
      setStep5FormData({
        ...step5FormData,
        [id]: checked,
      });
    }

  };

  const handleNextStep = () => {
    if (currentStep === 1 && isStep1Valid) {
      setCurrentStep(currentStep + 1);
    } else if (currentStep === 2 && isStep2Valid) {
      setCurrentStep(currentStep + 1);
    } else if (currentStep === 3 && isStep3Valid) {
      setCurrentStep(currentStep + 1);
    } else if (currentStep === 4 && isStep4Valid) {
      setCurrentStep(currentStep + 1);
    } else if (currentStep === 5 && isStep5Valid) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Show a confirmation dialog using SweetAlert2
    Swal.fire({
      title: 'Czy chcesz utworzyć stronę?',
      text: 'Proces może potrwać kilka minut',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Tak, utwórz stronę',
      cancelButtonText: 'Anuluj',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);

        try {
          console.log(step4FormData)
          console.log(step5FormData)
          const response = await axios.post(
            'https://api.app.hubone.tech/createInstance',
            {
              ...step1FormData,
              ...step2FormData,
              ...step3FormData,
              ...step4FormData,
              ...step5FormData,
            }
          );

          console.log(response.data);

          if (response.data.success) {
            setIsSuccess(true);
          } else {
            Swal.fire(
              'Błąd',
              `Wystąpił błąd podczas tworzenia instancji: ${response.data.error}`,
              'error'
            );
          }
        } catch (error) {
          console.error(error);

          Swal.fire('Błąd', 'Wystąpił błąd podczas tworzenia instancji', 'error');
        } finally {
          setIsLoading(false);
          setIsFormHidden(true); // Hide the form after submission
        }
      }
    });
  };

  const handleGoToListaInstancji = () => {
    // Add logic to navigate to "/lista-instancji"
    // For example, using react-router-dom history.push
    // history.push('/lista-instancji');
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Dodaj nową stronę</h4>
              <p className={classes.cardCategoryWhite}>
                Uzupełnij formularz, a następnie poczekaj na instalację Twojej nowej strony
              </p>
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <div>Trwa instalacja, nie zamykaj tego okna...</div>
              ) : (
                <div>
                  {isSuccess ? (
                    <div>
                      <h3>Strona utworzona!</h3>

                      <p>Adres strony: https://app.hubone.tech/{step1FormData.siteName}</p>
                      <p>Tytuł strony: {step2FormData.siteTitle}</p>
                      <p>Slogan: {step2FormData.siteSlogan}</p>
                      <Button
                        onClick={() =>
                          window.open(
                            `https://app.hubone.tech/${step1FormData.siteName}`,
                            "_blank"
                          )
                        }
                        color="primary"
                      >
                        Przejdź do strony
                      </Button>
                      <br />
                      <p>E-mail administratora: {step3FormData.adminEmail}</p>
                      <p>Login administratora: {step3FormData.adminLogin}</p>
                      <p>Hasło administratora: {step3FormData.adminPass}</p>
                      <p>Panel administratora: https://app.hubone.tech/{step1FormData.siteName}/wp-admin</p>
                      <Button
                        onClick={() =>
                          window.open(
                            `https://app.hubone.tech/${step1FormData.siteName}/wp-admin`,
                            "_blank"
                          )
                        }
                        color="primary"
                      >
                        Przejdź do panelu
                      </Button>
                      <br /><br />
                      <NavLink to="/konsola/lista-instancji" className={classes.navLink}>
                        <Button color="secondary">
                          Lista stron
                        </Button>
                      </NavLink>
                    </div>
                  ) : (
                    <div>
                      <form onSubmit={handleSubmit} hidden={isFormHidden} id="myForm">
                        {currentStep === 1 && (
                          <div>
                            <h4>Adres strony</h4>
                            <small>Wybierz adres Twojej strony, który zostanie dodany do hubone.tech/<b>adres-strony</b>. Tego ustawienia nie można potem zmienić. Nazwa strony może składac się z małych liter, cyfr i myślnika.</small>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                  labelText="Nazwa strony"
                                  id="siteName"
                                  value={step1FormData.siteName}
                                  onChange={handleInputChange}
                                  inputProps={{
                                    value: step1FormData.siteName,
                                  }}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  error={!isSiteNameValid && step1FieldTouched}
                                  helperText={
                                    !isSiteNameValid && step1FieldTouched
                                      ? 'Tylko litery oraz w środku cyfry i/lub myślnik'
                                      : null
                                  }
                                />
                              </GridItem>
                            </GridContainer>
                          </div>
                        )}
                        {currentStep === 2 && (
                          <div>
                            <h4>Dane strony</h4>
                            <small>Podaj tytuł i slogan strony. Te ustawienia będzie można dowolnie zmieniać w panelu administracyjnym strony.</small>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                  labelText="Tytuł strony"
                                  id="siteTitle"
                                  value={step2FormData.siteTitle}
                                  onChange={handleInputChange}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                  labelText="Slogan"
                                  id="siteSlogan"
                                  value={step2FormData.siteSlogan}
                                  onChange={handleInputChange}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>
                            </GridContainer>
                          </div>
                        )}
                        {currentStep === 3 && (
                          <div>
                            <h4>Dane administratora</h4>
                            <small>Stwórz pierwsze konto administratora, w przyszłości będzie można zmienić te dane i dodać kolejne w panelu strony.</small>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                  labelText="E-mail administratora"
                                  id="adminEmail"
                                  value={step3FormData.adminEmail}
                                  onChange={handleInputChange}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  error={!isValidEmail(step3FormData.adminEmail) && step3FieldTouched}
                                  helperText={
                                    !isValidEmail(step3FormData.adminEmail) && step3FieldTouched
                                      ? 'Nieprawidłowy adres e-mail'
                                      : null
                                  }
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                  labelText="Login"
                                  id="adminLogin"
                                  value={step3FormData.adminLogin}
                                  onChange={handleInputChange}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                  labelText="Hasło"
                                  inputProps={{
                                    type: 'password',
                                  }}
                                  id="adminPass"
                                  value={step3FormData.adminPass}
                                  onChange={handleInputChange}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>
                            </GridContainer>
                          </div>
                        )}
                        {
                          currentStep === 4 && (
                            <div>
                              <h4>Wybierz motyw</h4>
                              <small>Pora na wybór przewodniego stylu strony. Kolory, czcionki i wszystkie sekcje będziesz mógł dopasować idealnie do swoich potrzeb.</small>
                              <div style={{ margin: "30px 0px 0px" }}>
                                <GridContainer>

                                  <GridItem xs={12} sm={12} md={4}>
                                    {/* Radio button 1 */}
                                    <label className="imgtheme">
                                      <input
                                        type="radio"
                                        id="theme"
                                        value="Premium"
                                        checked={step4FormData.theme === 'Premium'}
                                        onChange={handleInputChange}
                                      />
                                      Elegant premium
                                      <img src="/img/theme1.png" alt="Premium" />
                                      <Button
                                        target="_blank"
                                        href="https://app.hubone.tech/demo/files/app.hubone-premium.fig"
                                        variant="contained"
                                        color="transparent">
                                        <Close />
                                        Pobierz Figmę
                                      </Button>
                                    </label>

                                  </GridItem>

                                </GridContainer>
                              </div>
                            </div>
                          )
                        }
                        {
                          currentStep === 5 && (
                            <div>
                              {/* Checkboxes for Step 4 */}
                              <h4>Dodatkowe akcje</h4>

                              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '50px' }}>
                                {/* First Column */}
                                {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                  Integracje:
                                  <label>
                                    <input
                                      type="checkbox"
                                      id="option1"
                                      checked={step5FormData.option1}
                                      onChange={handleInputChange}
                                    />
                                    SalesManago
                                  </label>
                                  <label>
                                    <input
                                      type="checkbox"
                                      id="option2"
                                      checked={step5FormData.option2}
                                      onChange={handleInputChange}
                                    />
                                    Makieta BIM Cadaplus
                                  </label>
                                </div> */}

                                {/* Second Column */}
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                       
                                  <label>
                                    <input
                                      type="checkbox"
                                      id="hidegoogle"
                                      checked={step5FormData.hidegoogle}
                                      onChange={handleInputChange}
                                    />
                                    <b>Ukryj stronę z widoczności dla Google</b> - Twoja strona nie będzie widoczna dla wyszukiwarek do momentu zmiany tego ustawienia w panelu adminsitratora strony
                                  </label>
                                  <label>
                                    <input
                                      type="checkbox"
                                      id="contentimport"
                                      checked={step5FormData.contentimport}
                                      onChange={handleInputChange}
                                    />
                                    <b>Zaimportuj demo treść i układ</b> - utwórz swoją stronę ze startowym, gotowym układem zakładek. Następnie dostosuj ją do swoich wymagań.
                                  </label>
                                </div>
                              </div>


                            </div>
                          )
                        }
                        {currentStep === 6 && (
                          <div>
                            <h3>Podsumowanie</h3>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              {/* Column 1 */}
                              <div style={{ flex: 1 }}>
                                <p>Nazwa strony: {step1FormData.siteName}</p>
                                <p>Tytuł strony: {step2FormData.siteTitle}</p>
                                <p>Slogan: {step2FormData.siteSlogan}</p>
                              </div>

                              {/* Column 2 */}
                              <div style={{ flex: 1 }}>
                                <p>E-mail administratora: {step3FormData.adminEmail}</p>
                                <p>Login administratora: {step3FormData.adminLogin}</p>
                                <p>Hasło administratora: {`${step3FormData.adminPass.slice(0, 1)}.....${step3FormData.adminPass.slice(-1)}`}</p>
                              </div>

                              {/* Column 3 */}
                              <div style={{ flex: 1 }}>
                                <p>Motyw: {step4FormData.theme}</p>
                                <p>Dodatki: {getSelectedOptions()}</p>
                              </div>
                            </div>

                            <p>Sprawdź poprawność powyższych danych, a następnie uruchom instalacje. <b>Proces może potrwać kilka minut.</b></p>
                          </div>
                        )}
                        {/* ... Other steps and buttons ... */}
                      </form>
                      <CardFooter>
                        {currentStep > 1 && !isSuccess && (
                          <Button onClick={handlePreviousStep} color="primary">
                            Poprzedni krok
                          </Button>
                        )}
                        {currentStep < 6 && !isSuccess && (
                          <Button onClick={handleNextStep} color="primary" disabled={
                            (currentStep === 1 && !isStep1Valid) ||
                            (currentStep === 2 && !isStep2Valid) ||
                            (currentStep === 3 && !isStep3Valid) ||
                            (currentStep === 4 && !isStep4Valid) ||
                            (currentStep === 5 && !isStep5Valid)
                          }>
                            Następny krok
                          </Button>
                        )}
                        {currentStep === 6 && !isSuccess && (
                          <Button type="submit" color="primary" form="myForm">
                            Utwórz stronę
                          </Button>
                        )}
                      </CardFooter>
                    </div>
                  )}
                </div>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withStyles(styles)(UserProfile);
