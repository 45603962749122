import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';

// core components
import Konsola from './layouts/Konsola';
import Login from './views/Login/Login';

import './assets/css/material-dashboard-react.css?v=1.6.0';

const hist = createBrowserHistory();

const App = () => {
  return (
    <Router history={hist}>
      <Switch>
        <Route path="/konsola" component={Konsola} />
        <Route path="/login" component={Login} />
        <Route path="/" component={Konsola} />
        {/* Additional routes and components can be added here */}
      </Switch>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
