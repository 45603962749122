import React from 'react';
import classNames from 'classnames';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
// @material-ui/icons
import Clear from '@material-ui/icons/Clear';
import Check from '@material-ui/icons/Check';
// core components
import customInputStyle from '../../assets/jss/material-dashboard-react/components/customInputStyle';

function CustomInput({ ...props }: any) {
  const {
    classes,
    formControlProps,
    labelText,
    id,
    value,
    labelProps,
    inputProps,
    onChange,
    helperText,
    error,
    success
  } = props;

  const labelClasses = classNames({
    [' ' + classes.labelRootError]: error,
    [' ' + classes.labelRootSuccess]: success && !error
  });

  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true
  });

  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined
  });
  
  return (
    <FormControl
    {...formControlProps}
    className={formControlProps.className + ' ' + classes.formControl}
  >
    {labelText !== undefined ? (
      <InputLabel
        className={classes.labelRoot + labelClasses}
        htmlFor={id}
        {...labelProps}
      >
        {labelText}
      </InputLabel>
    ) : null}
    <Input
      classes={{
        root: marginTop,
        disabled: classes.disabled,
        underline: underlineClasses
      }}
      onChange={onChange}
      id={id}
      value={value}
      {...inputProps}
    />
    {error ? (
      <Clear className={classes.feedback + ' ' + classes.labelRootError} />
    ) : success ? (
      <Check className={classes.feedback + ' ' + classes.labelRootSuccess} />
    ) : null}
    {/* Render helper text if provided */}
    {helperText && (
      <div className={classes.helperText}>{helperText}</div>
    )}
  </FormControl>
  );
}

// CustomInput.propTypes = {
//   classes: PropTypes.object.isRequired,
//   labelText: PropTypes.node,
//   labelProps: PropTypes.object,
//   id: PropTypes.string,
//   inputProps: PropTypes.object,
//   formControlProps: PropTypes.object,
//   error: PropTypes.bool,
//   success: PropTypes.bool
// };

export default withStyles(customInputStyle)(CustomInput);
