import Person from '@material-ui/icons/Person';
import AddInstance from './views/AddInstance/AddInstance';
import InstancesList from './views/InstancesList/InstancesList';
import Login from './views/Login/Login';

const dashboardRoutes = [

  {
    path: '/lista-instancji',
    name: 'Lista stron',
    icon: 'list',
    component: InstancesList,
    layout: '/konsola'
  },
  {
    path: '/dodaj-instancje',
    name: 'Dodaj stronę',
    icon: 'add',
    component: AddInstance,
    layout: '/konsola'
  },

];

export default dashboardRoutes;
